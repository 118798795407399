import React, {useState, useEffect} from "react";
import { Checkbox } from "antd";
import "../../css/checkboxComponent.css";

const CheckboxCompoment = ({ checkBoxOptions = [], initialState = [], onValueChange = {}  }) => {
  const [checkedValues, setCheckedValues] = useState(initialState);

  // const handleCheckboxChange = (option) => {
  //   setCheckedValues((prevState) => {
  //     let updatedState;
  //     if (prevState.includes(option)) {
  //       updatedState = prevState.filter((value) => value !== option);
  //     } else {
  //       updatedState = [...prevState, option];
  //     }
  //     onValueChange(updatedState);
  //     return updatedState;
  //   });
  // };

  const handleCheckboxChange = (option) => {
    setCheckedValues((prevState) => {
      if (prevState.includes(option)) {
        return prevState.filter((value) => value !== option);
      } else {
        return [...prevState, option];
      }
    });
  };

  // this is to make sure that we propogate the user selected values in real time to the parent component till the Assessment Main Screen Component
  // we use this implementation because we would like to avoid the bad setState() implementation
  // as we do not want to update the state of the parent component before we update state of the child component which in this case is this Checkbox component. 
  useEffect(() => {
    onValueChange(checkedValues);
  }, [checkedValues, onValueChange]);
  

  return (
    <div className="">
      <div className="checkbox-wrapper">
        {checkBoxOptions.map((option, index) => (
          <div>
            <Checkbox
                key={index}
                checked={checkedValues.includes(option)}
                onChange={() => handleCheckboxChange(option)}>
              {option}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckboxCompoment;
