import React, { useState, useEffect } from 'react';
import AssessmentFormHeader from './AssessmentFormHeader';
import AssessmentFormFooter from './AssessmentFormFooter';
import QuestionAndAnswerForAssessmentForm from "../components/QuestionAndAnswerForAssessmentForm";
import ContinueButtonForAssessmentForm from './inputAnswerComponents/ContinueButtonForAssessmentForm';
import assessmentFormData from "../assessmentQuestions/list_of_questions_from_google_form.json";
import assessmentFormBackendKeys from "../assessmentQuestions/jsonBodyToSendToBackend.json";
import { useNavigate } from "react-router-dom";
import {notification, Spin} from "antd";
import "../css/main_screen.css";
import baseUrl from "../Utils/baseUrl";
import {getUserToken, notifyUser} from "../Utils/general";

const assessmentFormDataQuestions = assessmentFormData;
const totalNumberOfSections = 8;
const totalNumberOfQuestions = assessmentFormDataQuestions.length;

const AssessmentFormMainScreen = ({ user_details = {} }) => {

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [userSelectedAnswers, setAnswers] = useState([]);
    const [valueFromQuestionAndAnswerComponent, setValueFromQuestionAndAnswerComponent] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state
    const [errorVisible, setErrorVisible] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    // // State Management for the Dropdown Component
    // const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        // Load the saved answers from localStorage when the component mounts
        const user_specific_key = user_details.logged_in_user_id;
        const savedAnswers = JSON.parse(localStorage.getItem(user_specific_key)) || [];
        // console.loglog(`saved answers from the current user = ${user_specific_key} is `, savedAnswers);
        setAnswers(savedAnswers);

        // get current question index
        const checkpoint_question_index_number = user_details.logged_in_user_id + "_checkpoint_question_index_number";
        const question_number = localStorage.getItem(checkpoint_question_index_number);
        let final_question_number_to_be_rendered = question_number != undefined ? question_number : 0;
        // console.loglog("question number = " + question_number + " final question number is = " + final_question_number_to_be_rendered);
        final_question_number_to_be_rendered = parseInt(final_question_number_to_be_rendered);

        // // set the saved progress on the page
        // console.loglog("checking the totla number of questions coming up in the use effect hook = ", totalNumberOfQuestions);
        const value_1 = final_question_number_to_be_rendered;
        const saved_progress_value = (value_1 / parseInt(totalNumberOfQuestions));
        // console.loglog("saved_progress_value = ", saved_progress_value);
        setProgress(saved_progress_value * 100);

        // setting the current question index to display that question on the page
        final_question_number_to_be_rendered = (final_question_number_to_be_rendered == (totalNumberOfQuestions - 1)) ? (final_question_number_to_be_rendered - 1) : final_question_number_to_be_rendered;
        setCurrentQuestionIndex(parseInt(final_question_number_to_be_rendered));

        setLoading(false); // Set loading to false once the data is loaded
    }, []);

    const addAnswer = () => {
        const newAnswer = {
            [currentQuestionIndex]: valueFromQuestionAndAnswerComponent
        };
        const updatedAnswers = [...userSelectedAnswers, newAnswer];
        setAnswers(updatedAnswers);
    };

    const updateAnswer = () => {
        let updatedAnswers = [...userSelectedAnswers];
        if ((updatedAnswers[currentQuestionIndex] === null) || (updatedAnswers[currentQuestionIndex] === undefined)) {
            updatedAnswers[currentQuestionIndex] = {
                [currentQuestionIndex]: valueFromQuestionAndAnswerComponent
            }
        } else {
            updatedAnswers[currentQuestionIndex][currentQuestionIndex] = valueFromQuestionAndAnswerComponent;
        }
        setAnswers(updatedAnswers);
    }

    const addOrUpdateAnswer = () => {
        if (currentQuestionIndex in userSelectedAnswers) {
            updateAnswer();
        } else {
            addAnswer();
        }
    };

    const isTherePreviousAnswer = () => {
        if (currentQuestionIndex in userSelectedAnswers) {
            const previousData = userSelectedAnswers[currentQuestionIndex];
            const previousAnswer = (previousData != null) ? previousData[currentQuestionIndex] : null;
            // console.loglog("previous answer :- ", previousAnswer);
            if (previousAnswer != null) {
                return true;
            }
            return false;
        }
        return false;
    };

    const handleContinue = () => {
        // console.loglog("valueFromQuestionAndAnswerComponent : ", valueFromQuestionAndAnswerComponent);

        if (valueFromQuestionAndAnswerComponent == null) {
            if ((isTherePreviousAnswer() === false)) {
                setErrorVisible(true);
                return;
            }
        }

        if ((valueFromQuestionAndAnswerComponent !== null)) {
            addOrUpdateAnswer();
        }
        // console.loglog("in the continue handle function");
        if (currentQuestionIndex < assessmentFormDataQuestions.length - 1) {
            // handleStateResetForTheAnswerComponents();
            setProgress(((currentQuestionIndex + 1) / totalNumberOfQuestions) * 100);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setValueFromQuestionAndAnswerComponent(null);
            setErrorVisible(false);
            saveQuestions()
        } else {
            // setting the progress to 100% after selecting the last question
            setProgress(((currentQuestionIndex + 1) / totalNumberOfSections) * 100);
            const copy_value = valueFromQuestionAndAnswerComponent;
            setValueFromQuestionAndAnswerComponent(null);
            setErrorVisible(false);
            makeJSONBodyForAPI(copy_value);
            submitAssessmentForm();
        }
    };

    // This function is called by the child component
    const handleValueChangeFromTheQuestionAndAnswerComponent = (value) => {
        setValueFromQuestionAndAnswerComponent(value);
        // console.loglog("Received from QuestionAndAnswerComponent:", value);
    };

    const handlePreviousButton = () => {
        if ((valueFromQuestionAndAnswerComponent !== null)) {
            addOrUpdateAnswer();
        }

        // console.loglog("in the previous button handle function");
        if (currentQuestionIndex > 0) {
            // decreasing the progress
            setProgress(((currentQuestionIndex - 1) / totalNumberOfQuestions) * 100);
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        } else {
            alert("This is the first question!!");
        }
        setValueFromQuestionAndAnswerComponent(null);
        setErrorVisible(false);
    };

    const saveQuestions = () => {
        let updatedAnswers = [...userSelectedAnswers];
        if ((valueFromQuestionAndAnswerComponent !== null)) {
            if (currentQuestionIndex in userSelectedAnswers) {
                if ((updatedAnswers[currentQuestionIndex] === null) || (updatedAnswers[currentQuestionIndex] === undefined)) {
                    updatedAnswers[currentQuestionIndex] = {
                        [currentQuestionIndex]: valueFromQuestionAndAnswerComponent
                    }
                } else {
                    updatedAnswers[currentQuestionIndex][currentQuestionIndex] = valueFromQuestionAndAnswerComponent;
                }
                setAnswers(updatedAnswers);
            } else {
                const newAnswer = {
                    [currentQuestionIndex]: valueFromQuestionAndAnswerComponent
                };
                // console.loglog("newAnswer :- ", newAnswer);
                updatedAnswers = [...userSelectedAnswers, newAnswer];
                // console.loglog("updatedAnswers :- ", updatedAnswers);
                setAnswers(updatedAnswers);
            }
        }

        const user_specific_key = user_details.logged_in_user_id;
        // console.loglog("saved answers in this session are = ", updatedAnswers);

        // setting the current saved answers in the state as the new checkpoint for saved answers
        localStorage.setItem(user_specific_key, JSON.stringify(updatedAnswers));

        // setting the local storage for whether the assessment is saved and being exited or is it being submitted completely
        const assessmentFormStatus = "saved";
        const assessmentFormStatus_key_for_user = user_details.logged_in_user_id + "_assessment_form_status";
        localStorage.setItem(assessmentFormStatus_key_for_user, assessmentFormStatus);

        // saving the current question index number as the save checkpoint
        const checkpoint_question_index_number = user_details.logged_in_user_id + "_checkpoint_question_index_number"
        localStorage.setItem(checkpoint_question_index_number, currentQuestionIndex);
        // console.loglog("data set in local storage for the current question number = ", localStorage.getItem(checkpoint_question_index_number));
    }

    const handleSaveAndExitButton = () => {
        let updatedAnswers = [...userSelectedAnswers];
        if ((valueFromQuestionAndAnswerComponent !== null)) {
            if (currentQuestionIndex in userSelectedAnswers) {
                if ((updatedAnswers[currentQuestionIndex] === null) || (updatedAnswers[currentQuestionIndex] === undefined)) {
                    updatedAnswers[currentQuestionIndex] = {
                        [currentQuestionIndex]: valueFromQuestionAndAnswerComponent
                    }
                } else {
                    updatedAnswers[currentQuestionIndex][currentQuestionIndex] = valueFromQuestionAndAnswerComponent;
                }
                setAnswers(updatedAnswers);
            } else {
                const newAnswer = {
                    [currentQuestionIndex]: valueFromQuestionAndAnswerComponent
                };
                // console.loglog("newAnswer :- ", newAnswer);
                updatedAnswers = [...userSelectedAnswers, newAnswer];
                // console.loglog("updatedAnswers :- ", updatedAnswers);
                setAnswers(updatedAnswers);
            }
        }

        const user_specific_key = user_details.logged_in_user_id;
        // console.loglog("saved answers in this session are = ", updatedAnswers);

        // setting the current saved answers in the state as the new checkpoint for saved answers
        localStorage.setItem(user_specific_key, JSON.stringify(updatedAnswers));

        // setting the local storage for whether the assessment is saved and being exited or is it being submitted completely
        const assessmentFormStatus = "saved";
        const assessmentFormStatus_key_for_user = user_details.logged_in_user_id + "_assessment_form_status";
        localStorage.setItem(assessmentFormStatus_key_for_user, assessmentFormStatus);

        // saving the current question index number as the save checkpoint
        const checkpoint_question_index_number = user_details.logged_in_user_id + "_checkpoint_question_index_number"
        localStorage.setItem(checkpoint_question_index_number, currentQuestionIndex);
        // console.loglog("data set in local storage for the current question number = ", localStorage.getItem(checkpoint_question_index_number));

        navigate("/dashboard");
    };

    const makeJSONBodyForAPI = (copy_value) => {
        const backend_keys = Object.keys(assessmentFormBackendKeys);
        const backend_keys_length = backend_keys.length;
        // console.loglog("userSelectedAnswers = ", userSelectedAnswers);
        for (var i = 0; i < (backend_keys_length - 1); i++) {
            const key = backend_keys[i];
            // console.loglog("key = ", key);
            assessmentFormBackendKeys[key] = userSelectedAnswers[i][i];
        }
        assessmentFormBackendKeys[backend_keys[backend_keys_length - 1]] = copy_value;
        // console.loglog("the json body for the backend api is ");
        // console.loglog(assessmentFormBackendKeys);
    };

    const submitAssessmentForm = async () => {
        // console.loglog(assessmentFormBackendKeys);
        setSubmitting(true)
        await fetch(`${baseUrl}create-assessment`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getUserToken()}`
            },
            body: JSON.stringify(assessmentFormBackendKeys)
        })
            .then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    // console.logerror('Failed to submit the assessment failed:', errorData);
                    openErrorNotification(errorData);
                    throw new Error('Failed to submit the assessment failed');
                }
                return response.json();
            })
            .then(data => {
                // console.loglog('Successfully submitted the assessment:', data);
                const assessmentFormStatus = "completed";
                const assessmentFormStatus_key_for_user = user_details.logged_in_user_id + "_assessment_form_status";
                localStorage.setItem(assessmentFormStatus_key_for_user, assessmentFormStatus);

                // setting the question checkpoint back to 0 in order to make sure the functionality stays intact
                // this is because when the user submits one assessment he should be able to take new assessment
                // so for that I need to reset the local storage to the initial values in order to make it as a fresh start

                // therefore, I am starting off by setting the question number checkpoint for that user to be 0. 
                const checkpoint_question_index_number = user_details.logged_in_user_id + "_checkpoint_question_index_number"
                localStorage.setItem(checkpoint_question_index_number, parseInt(0));

                // now reset the local storage for that contains the saved answers to an mepty array
                const resetANswersToEmptyArray = [];
                const user_specific_key = user_details.logged_in_user_id;
                localStorage.setItem(user_specific_key, JSON.stringify(resetANswersToEmptyArray));

                localStorage.setItem('assessment_id', data.data.id)

                // now taking the user to the subscroiption page so that he would be only be able to go further into the application once he subscribes
                navigate("/pricing");
            })
            .catch(error => {
                setSubmitting(false)
                notifyUser('error',error.message, 'Error Submitting Assessment')
                // console.logerror('An error occurred:', error);
            });
    };

    const openErrorNotification = (notificationParameters) => {
        // console.loglog("notificationParameters = ", notificationParameters);
        api["error"]({
            message: 'Failed to submit the assessment failed',
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    if (loading) {
        return <div>Loading...</div>; // Or any loading indicator you prefer
    }

    return (
        <div>
            <Spin spinning={submitting}>
                <span>{contextHolder}</span>
                <AssessmentFormHeader
                    progress={progress}
                    sectionInformationToBeDisplayed={assessmentFormDataQuestions[currentQuestionIndex]}
                    totalNumberOfSections={totalNumberOfSections}
                    onPreviousButtonClick={handlePreviousButton}
                    onSaveAndExitButtonClick={handleSaveAndExitButton}
                />
                <QuestionAndAnswerForAssessmentForm
                    uniqueKey={currentQuestionIndex}
                    questionToBeDisplayedQuestion={assessmentFormDataQuestions[currentQuestionIndex]}
                    onValueChange={handleValueChangeFromTheQuestionAndAnswerComponent}
                    savedQuestionData={userSelectedAnswers}
                />
                {errorVisible && (
                    <div className='errorContainer'>Please select an option before clicking on the Continue button</div>
                )}
                {/* <ContinueButtonForAssessmentForm onClick={handleContinue} /> */}
                <div className="assessmentFooter">
                <div className="row">
                    <div className="col">
                        <button className="btn btn-white shadow p-3 fw-bold" onClick={handlePreviousButton}>Previous</button>
                    </div>
                    <div className="col">
                        <button className="btn btn-white shadow p-3 fw-bold float-lg-end float-md-end" onClick={handleContinue}>Continue</button>
                    </div>
                </div>
            </div>
            </Spin>
        </div>
    );
}

export default AssessmentFormMainScreen;
