import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { router } from './router';
import { RouterProvider } from "react-router-dom";
import {TemplateContainer} from "./components/v1/TemplateContainer";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <TemplateContainer>
          <RouterProvider router={router} />
      </TemplateContainer>
  </React.StrictMode>
);
