let base_url = "";
let browser_url = "";

//getting the base url from the browser
if (typeof window !== "undefined") {
  browser_url = window.location.host;
}

switch (browser_url) {
  case "localhost:3000":
    base_url = "https://api.anvillist.com/api/v1/";
    // base_url = "http://127.0.0.1:8000/api/v1/";
    break;

  case "localhost:3001":
    base_url = "http://127.0.0.1:8000/api/v1/";
    break;

  case "localhost:5000":
    base_url = "https://api.anvillist.com/api/v1/";
    break;

  case "business.anvillist.com":
    base_url = "https://api.anvillist.com/api/v1/";
    break;

  default:
    base_url = "https://api.anvillist.com/api/v1/";
    break;
}
export default base_url;
