import React, {useEffect, useState} from "react";
import "../css/dashboard.css";
import {getUserDetails, notifyUser} from "../Utils/general";
import { useNavigate } from 'react-router-dom';
import MainButton from "../components/v1/MainButton";
import cooperation1 from "../assets/cooperation1.png";
import tube from "../assets/tube.png";
import resource_library from "../assets/resource_library.png";
import business_profile from "../assets/business_profile.png";
import dataroomImg from "../assets/dataroom.png";

const isNavbarForDashboard = true;

const Dashboard = (props) => {
    const navigate = useNavigate(); // Initialize the navigation hook
    const [assessmentStatus, setAssessmentStatus] = useState('')

    useEffect(() => {
        const assessmentId  = `${getUserDetails().id}_assessment_form_status`;
        if (localStorage.getItem(assessmentId) === "saved") {
            setAssessmentStatus('Continue');
        }else if (localStorage.getItem(assessmentId) === "completed") {
            setAssessmentStatus('Start');
        } else {
            setAssessmentStatus('Start');
        }
    }, []);

  return (
    <div className="">
        <div className="mx-auto mobile-full-width" style={{width:'90%'}}>
            {/*<h3 className="text-center font-weight-bold">Welcome to Anvil List, your trusted partner on the path to investment readiness.</h3>*/}
            {/*<p className="lead text-center mb-4">For the best experience, we recommend taking our assessment and setting up your profile. This will help us identify the gaps in your business and work towards closing them.</p>*/}
            <p className="mb-1 mt-5 text-small">👋🏼 Hey {getUserDetails()?.name},</p>
            <h3 className="fw-bold mb-5">Look at the endless possibilities to grow your business</h3>
        </div>
        <div className="mx-auto mobile-full-width mt-5" style={{width:'90%'}}>
            <div className="row">
                <div className="col-lg-3 col-md-3 mb-4 col-xs-12 col-sm-12 mt-3">
                    <div className="shadow p-4 dashboard-card">
                        <img src={tube} alt="card img" className='img-fluid' width='80px'/>
                        <div className="dashboard-card-text">
                            <p className="lead fw-bold mt-4">Take an Assessment</p>
                            <p className="">To get the most out of our platform, please take a few minutes to complete our assessment.</p>
                        </div>
                        <MainButton classType='col btn btn-small btn-primary m-1' title={assessmentStatus} navigatePath='/assessmentForm' />
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 mb-4 col-xs-12 col-sm-12 mt-3">
                    <div className="shadow p-4 dashboard-card">
                        <img src={business_profile} alt="card img" className='img-fluid' width='80px'/>
                        <div className="dashboard-card-text">
                            <p className="lead fw-bold mt-4">Complete your profile</p>
                            <p className="">To showcase you to outstanding business experts, you need to complete your profile.</p>
                        </div>
                        <MainButton classType='col btn btn-small btn-primary m-1' title='View Profile' navigatePath='/my-profile' />
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 mb-4 col-xs-12 col-sm-12 mt-3">
                    <div className="shadow p-4 dashboard-card">
                        <img src={resource_library} alt="card img" className='img-fluid' width='80px'/>
                        <div className="dashboard-card-text">
                            <p className="lead fw-bold mt-4">View Reports</p>
                            <p className="">Analyze in-depth reports to uncover your business's strength and weaknesses.</p>
                        </div>
                        <MainButton classType='col btn btn-small btn-primary m-1' title='View Report' navigatePath='/recommendation' />
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 mb-4 col-xs-12 col-sm-12 mt-3">
                    <div className="shadow p-4 dashboard-card">
                        <img src={dataroomImg} alt="card img" className='img-fluid' width='80px'/>
                        <div className="dashboard-card-text">
                            <p className="lead fw-bold mt-4">Data Room</p>
                            <p className="">Upload and store documents but with a purpose to attract investors for seeking funding</p>
                        </div>
                        <MainButton classType='col btn btn-small btn-primary m-1' title='Access Data Room' navigatePath='/data-room' />
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 mb-4 col-xs-12 col-sm-12 mt-3">
                    <div className="shadow p-4 dashboard-card">
                        <img src={cooperation1} alt="card img" className='img-fluid' width='80px'/>
                        <div className="dashboard-card-text">
                            <p className="lead fw-bold mt-4">Meet with Mentors</p>
                            <p className="">Make the most out of our exclusive mentorship guidance.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*<div className="w-50 mx-auto">
            <div className="p-4 shadow mb-3 card-radius">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <p className="mb-0 fw-bold">Take an assessment</p>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <div className="row">
                            <MainButton classType='col btn btn-small btn-primary-outline m-1' title='Learn More' hasOnClick={true} onclickFunction={() => notifyUser('success', 'Take assessment to find gaps in your business through our report and remediations ','Tip')}/>
                            <MainButton classType='col btn btn-small btn-primary m-1' title={assessmentStatus} navigatePath='/assessmentForm' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-4 shadow mb-3 card-radius">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <p className="mb-0 fw-bold">View reports & gaps</p>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <div className="row">
                            <MainButton classType='col btn btn-small btn-primary-outline m-1' title='Learn More' hasOnClick={true} onclickFunction={() => notifyUser('success', 'View all gaps taken after report generation','Tip')}/>
                            <button className="col btn btn-small btn-primary-disabled m-1" disabled={true}>Start</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-4 shadow mb-3 card-radius">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <p className="mb-0 fw-bold">Connect with professionals</p>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <div className="row">
                            <MainButton classType='col btn btn-small btn-primary-outline m-1' title='Learn More' hasOnClick={true} onclickFunction={() => notifyUser('success', 'Connect with professionals to help remediate your gaps','Tip')}/>
                            <button className="col btn btn-small btn-primary-disabled m-1" disabled={true}>Start</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-4 shadow mb-3 card-radius">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <p className="mb-0 fw-bold">Showcase your business to investors</p>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <div className="row">
                            <MainButton classType='col btn btn-small btn-primary-outline m-1' title='Learn More' hasOnClick={true} onclickFunction={() => notifyUser('success', 'After remediation your business will now be fit enough to be showcased to investors','Tip')}/>
                            <button className="col btn btn-small btn-primary-disabled m-1" disabled={true}>Start</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>*/}
    </div>
  );
};


export default Dashboard;
