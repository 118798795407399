import React, {useEffect, useState} from "react";
import {FreshAccessUser} from "./FreshAccessUser";
import {RoomFileList} from "./RoomFileList";
import {UploadFiles} from "./UploadFiles";

export const DataRoomPage = () => {

  const [hasData, setData] = useState(false)

  const documents = [
    { id: 1, name: 'Pitch Deck', status: 'pending', active: false },
    { id: 2, name: 'Business Plan', status: 'pending', active: false },
    { id: 3, name: 'Financial Projections', status: 'pending', active: false },
    { id: 4, name: 'Cap Table', status: 'pending', active: false },
    { id: 5, name: 'Incorporation Documents', status: 'pending', active: true },
    { id: 6, name: 'Founder Resumes', status: 'pending', active: false },
    { id: 7, name: 'Market Research', status: 'pending', active: false },
    { id: 8, name: 'Traction Metrics', status: 'pending', active: false },
    { id: 9, name: 'Contracts and Agreements', status: 'pending', active: false },
    { id: 10, name: 'Intellectual Property (IP) Documents', status: 'pending', active: false },
    { id: 11, name: 'Customer Testimonials or Case Studies', status: 'pending', active: false },
    { id: 12, name: 'Regulatory Compliance Certificates', status: 'pending', active: false },
  ];

  useEffect(()  => {
    renderComponents()
  },[])

  const renderComponents = () => {
    if (localStorage.getItem('newDataRoomUser') && localStorage.getItem('newDataRoomUser') === 'no'){
      // return <RoomFileList documents={documents} />;
      return <UploadFiles documents={documents} />;
    }else{
      return <FreshAccessUser />;
    }
  }

  return (
      <>{renderComponents()}</>
  )
}