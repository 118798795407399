import React, { useEffect, useState } from 'react';
import SidebarComponent from './SidebarComponent';
import UserToUserChatSectionComponent from './UserToUserChatSectionComponent';
import attachIcon from "../../assets/fileAttachIcon.png";
import sendButtonIcon from "../../assets/sendButton.png";
import '../../css/UserChatInterface.css';
import MainButton from "../v1/MainButton";

const UserChatInterface = ({ user_details }) => {

  const [selectedProfessional, setSelectedProfessional] = useState(null);

  const handleActiveChatChange = (professional_id) => {
    // setActiveChatId(chatId);
    // console.log("Active chat changed to:", chatId);  // For demonstration, you can handle it as needed
    // alert(`professional id = ${chatId}`);

    // now here I need to handle the API call to fetch all the messages with respect to the profesional selected by the user.
    // the change the state to load it up. 
    // also need to implement the loader and the error notification component as well with the API call. 
    setSelectedProfessional(professional_id);
  };

  return (
    <div className="row">
      {/* Sidebar */}
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <SidebarComponent onActiveChatChange={handleActiveChatChange} user_details={user_details} />
      </div>
      <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        {/* Chat Section */}
        {selectedProfessional === null ? (
            <div className='' align='center' style={{marginTop:'10%'}}>
              <p>Select a Professional to start a conversation</p>
              <button className='col btn btn-small btn-primary m-1' onClick={() => window.location.href = '/recommendation'}>View Recommendation</button>
            </div>
        ) : (
            <UserToUserChatSectionComponent selectedProfessional={selectedProfessional} user_details={user_details}/>
        )}
      </div>
    </div>
  );
};

export default UserChatInterface;
