import React, {useEffect, useState} from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const colors = [
    'rgba(255, 99, 132, 0.8)', // Red
    'rgba(54, 162, 235, 0.8)', // Blue
    'rgba(75, 192, 192, 0.8)', // Green
    'rgba(255, 206, 86, 0.8)', // Yellow
    'rgba(153, 102, 255, 0.8)', // Purple
    'rgba(255, 159, 64, 0.8)', // Orange
];

export function ProfilePieChart({ splitFunding }) {

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    useEffect(() => {

        const labels = splitFunding.map((item) => item.description);
        const data = splitFunding.map((item) => parseInt(item.percentage));

        // Assign colors from the array
        const backgroundColor = [];
        for (let i = 0; i < data.length; i++) {
            backgroundColor.push(colors[i % colors.length]); // Cycle through colors
        }
        const borderColor = backgroundColor.map((color) => color);

        setChartData({
            labels: labels,
            datasets: [
                {
                    label: 'Funding Distribution',
                    data: data,
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: 1,
                },
            ],
        });
    },[splitFunding])


    return <Pie data={chartData} height={200} options={{ maintainAspectRatio: false }}/>
}