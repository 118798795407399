import React from "react";
import MainNavbarForDashboard from "../navbarComponents/NavbarForMainDashbaord";
import RemediationMainScreeContent from "./RemediationMainScreenContent";
import "../../css/mainDashboardPage.css";
const isNavbarForDashboard = true;

const RemediationPage = () => {
    const user_details = JSON.parse(sessionStorage.getItem("user_details"));
    // console.log("in the remediation timelines page where the logged_in_user_id = ", user_details);
    return (
        <div className="completePageContainer">
            <RemediationMainScreeContent user_details={user_details} />
        </div>
    );
};

export default RemediationPage;