import React, { useEffect, useRef } from 'react';
import { Line } from '@ant-design/charts';

const LineChart = () => {

    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef.current) {
            const chartContainer = chartRef.current.querySelector('[data-chart-source-type="Ant Design Charts"]');
            if (chartContainer) {
                chartContainer.style.height = 'auto'; // Adjust height as needed
            }
        }
    }, []);

    const data = [
        { year: '2019', value: 5000, category: 'Industry Standard' },
        { year: '2020', value: 6500, category: 'Industry Standard' },
        { year: '2021', value: 7000, category: 'Industry Standard' },
        { year: '2022', value: 8000, category: 'Industry Standard' },
        { year: '2019', value: 4500, category: 'Company' },
        { year: '2020', value: 5000, category: 'Company' },
        { year: '2021', value: 5500, category: 'Company' },
        { year: '2022', value: 6000, category: 'Company' }
    ];

    const formattedData = data.map(item => ({
        ...item,
        formattedValue: `$${(item.value / 1000).toFixed(1)}B`
    }));

    // console.log("formattedData = ", formattedData);

    const config = {
        data: formattedData,
        xField: 'year',
        yField: 'formattedValue',
        colorField: 'category',
        color: ['#FDB52B', '#47B881'], // Colors for 'Industry Standard' and 'Company'
        backgroundColor: ["#FDB52B", "#47B881"],
        axis: {
            x: {
                label: null
            }
        },
        legend: {
            position: 'top',
        },
        smooth: true,
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
        autoFit: true,
        height: 150,
        lineStyle: {
            lineWidth: 3,
        }
    };

    return (
        <div ref={chartRef}>
            <Line {...config} />
        </div>
    );
};

export default LineChart;
