import React, {useEffect, useState} from "react";
import SubscriptionPricingContainer from "../components/subscriptionPricing/SubscriptionPricingContainer";
import "../css/FixedScrollingMainScreenContent.css";
import shootingStarIcon from "../assets/shootingStarIcon.png";
import selectedImg from "../assets/selected.png";
import {serverRequest} from "../Utils/server_request";
import {notifyUser} from "../Utils/general";
import {Spin} from "antd";

const SubscriptionPlans = () => {

    const [packages, setPackages] = useState([])
    const [loading, setLoading] = useState(false)

    let businessPackages = ['Business Standard', 'Business Premium']
    if (localStorage.getItem('assessment_id')){
        businessPackages.push('One-Time Report')
    }

    useEffect(() => {
        fetchPackages()
    },[])

    const fetchPackages = () => {
        setLoading(true)
      serverRequest('GET','subscription-packages').then(result => {
          setPackages(result.data)
          setLoading(false)
      }).catch(error  => {
          setLoading(false)
          notifyUser('error',error.message)
      })
    }

    const getPackageDescription = (_name) => {
        if (_name === 'One-Time Report'){
            return 'We have something easy and special for you, pay a nominal fee to get aa completed assessment report of your business';
        }else if(_name === 'Business Standard'){
            return 'From diagnosing your current state to providing actionable solutions, we\'ll help you identify opportunities.';
        }else{
            return 'Upgrade your business with our comprehensive remediation package. Enjoy all the benefits of our diagnostics.'
        }
    }

    const makePayment = (package_id) => {
        setLoading(true)
        let url = `checkout/${package_id}`;
        if (localStorage.getItem('assessment_id'))
            url = url + `?assessment_id=${localStorage.getItem('assessment_id')}`;

        serverRequest('GET', url).then(result => {
            if (result){
                window.location.replace(result?.payment_url)
            }
        }).catch(error  => {
            setLoading(false)
            notifyUser('error',error.message)
        })
    }

    return (
        <Spin spinning={loading}>
            <div className="w-75 mx-auto">
                <p className="lead">Choose your subscription plan</p>
                <div className="row">
                    {packages.filter(p => businessPackages.includes(p.name)).map(_package =>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" key={_package.name}>
                            <div className="pricing-card shadow p-4">
                                <img src={shootingStarIcon} alt="" className="img-fluid" width='20px'/>
                                <h5 className="pricing-header mt-3">{_package.name}</h5>
                                <small className="pricing-header-sub">{getPackageDescription(_package.name)}</small>

                                <div className="mt-4">
                                    <h5 className="pricing-header">${_package.price} </h5>
                                    <p className="pricing-header-sub">{ _package?.subscription_period === 'once' ? 'one-time': _package?.subscription_period }</p>
                                </div>
                                <div className="mt-4 pricing-detail">
                                    {_package.details.split(',').map(item =>
                                        <p className="mb-2 pricing-list">
                                            <img className="img-fluid" src={selectedImg} alt="" width='14px' style={{verticalAlign:'none'}}/> {item}</p>
                                    )}
                                </div>
                                <button className="btn btn-primary w-100 mt-4 fw-bold" onClick={() => makePayment(_package.id)}>{_package.name === 'One-Time Report' ? 'Buy Report Only': 'Subscribe now'}</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Spin>
    )
};

export default SubscriptionPlans;