import React from "react";

export const ProfileMiniCards = ({ icon, heading, subtext }) => {
  return (
      <div className="row">
          <div className="col-1">
              <i className={`bi ${icon} icon-large`} />
          </div>
          <div className="col-11">
              <p className="mb-1">{heading}</p>
              <p className="">{subtext}</p>
          </div>
      </div>
  )
}