import React from "react";
import analysisIcon from "../../../assets/linechartAnalysis.png";
import rankingIcon from "../../../assets/medal.png";

const MarketOpportunityComponentForInvestmentReadiness = ({
    sectionData = {},
    analysis = [],
    rankingData = "Ranking Value not available",
    completeReportData = {}
}) => {

    var ranking_class_name = "#4B4B4B";
    if (rankingData === "Below Average") {
        ranking_class_name = "#F64C4C";
    } else if (rankingData === "Average") {
        ranking_class_name = "#FE9B0E";
    } else if (rankingData === "Above Average") {
        ranking_class_name = "#47B881";
    } else {
        ranking_class_name = "#4B4B4B";
    }

    let analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    if (Array.isArray(analysis)) {
        analysisSectionTextDescription = analysis[2] ? analysis[2] : "Analysis is not available at this moment for this metric.";
    }
    else if (typeof analysis === "object" && analysis !== null && !Array.isArray(analysis)) {
        analysisSectionTextDescription = analysis["Analysis"];
    } else {
        analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    }


    const assessment = completeReportData?.assessment || {};

    return (
        <div className="investmentReadinessMarketOpportunitySection">
            <div className="investmentReadinessMarketOpportunitySectionHeader">
                <div className="investmentReadinessMarketOpportunitySectionTitleIndex">
                    01
                </div>
                <div className="investmentReadinessMarketOpportunitySectionTitleName">
                    MARKET OPPORTUNITY
                </div>
            </div>
            <div className="investmentReadinessMarketOpportunitySectionContent">
                <div className="nonGraphicalContentForMarketOpportunity">
                    <div className="analysisContentForMarketOpportunity">
                        <div className="analysisContentTitle">
                            <img
                                src={analysisIcon}
                                alt="Idea"
                                width="15px"
                                height="15px"
                            ></img>
                            <div className="analsyisTitleName">Analysis</div>
                        </div>
                        <div className="analysisContentDescription">{analysisSectionTextDescription}</div>
                    </div>
                    <div className="rankingContentForMarketOpportunity">
                        <div className="rankingContentTitle">
                            <img
                                src={rankingIcon}
                                alt="Idea"
                                width="20px"
                                height="20px"
                            ></img>
                            <div className="rankingTitleName">Ranking</div>
                        </div>
                        <div className="rankingContentDescription">
                            <div style={{ borderColor: ranking_class_name, padding: '10px', border: `2px solid ${ranking_class_name}`, minWidth: '20%',borderRadius: '10px', textAlign: 'center' }}>
                                <span style={{ color: ranking_class_name }}>{rankingData}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gapSection"></div>
                <div className="graphicalComponentForMarketOpportunity">
                    <div class="outer-circle">
                        <div class="outerText">
                            <div>Total Addressable Market</div>
                            <div className="subOuterTextPartTwo">{assessment?.market_segment_target || ""}</div>
                        </div>
                        <div class="inner-circle">
                            <div className="innerText">
                                <div>Serviceable Addressable Market</div>
                                <div className="subInnerTextPartTwo">{assessment?.total_market_demand_for_product || ""} of TAM</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rankingContentForMarketOpportunity" style={{ marginTop: "3%" }}>
                <div className="rankingContentTitle">
                    <div className="rankingTitleName">Requires Remediation</div>
                </div>
                <div className="analysisContentDescription">
                    {sectionData && (
                        <>
                            {sectionData["Needs fixing"] ? `Yes, ${sectionData["Reason"]}` : 'No remediation required.'}
                        </>
                    )}
                </div>
            </div>
            <div className="investmentReadinessSectionDivider"></div>
        </div>
    );
};

export default MarketOpportunityComponentForInvestmentReadiness;
