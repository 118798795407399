import React from "react";
import { useNavigate } from 'react-router-dom';

const MainButton = ({ classType, title, navigatePath, hasOnClick= false, onclickFunction = null, disabled=false }) => {

    const navigate = useNavigate();

    return (
        <button
            className={classType}
            onClick={hasOnClick ? onclickFunction : () => navigate(navigatePath)} disabled={disabled}>
            {title}
        </button>
    );
};

export default MainButton;
