import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import cooperation1 from "../assets/cooperation1.png";

import "../css/mainDashboardPage.css";
import {Spin} from "antd";
import {serverRequest} from "../Utils/server_request";
import {isSubscribed, notifyUser} from "../Utils/general";
const isNavbarForDashboard = true;

const RecommendedProfessinalProfilePage = () => {

    const[loading, setLoading] = useState(false)
    const[message, setMessage] = useState('')

    const navigate = useNavigate();
    const location = useLocation();
    const { professionalData } = location.state || {};
    // console.log("in the recommended professional profile detail page where the professionalPersonalInformation = ", professionalPersonalInformation);

    const handleBackButtonClick = () => {
        // navigate("/mainDashboard");
        navigate(-1);  // This moves the navigation history back by one
    }

    const sendMessage = () => {
        if (!isSubscribed()){
            return notifyUser('error', 'You need to subscribe or upgrade package to contact professional')
        }
        const payload = {
            message: message,
            is_file: false
        }
        setLoading(true)
      serverRequest('POST',`send-message/user/${professionalData?.id}`, payload).then(result => {
          if (result){
              setMessage('')
              setLoading(false)
              notifyUser('success', `Message sent to ${professionalData?.rep_business_name}!`)
          }
      }).catch(error => {
          setLoading(false)
          notifyUser('error',error.message, 'Failed to send')
      })
    }

    return (
        <div className="">
           <div className="row">
               <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                   <img src={cooperation1} alt="professional-img" className="img-fluid" width='100px'/>
               </div>
               <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                   <p className="lead fw-bold">{professionalData?.rep_business_name}</p>
                   <p className="text-small"><i className="bi bi-geo"/> {professionalData?.profile.location}</p>
               </div>
               <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                   <div>
                       <button className="btn btn-dark fw-bold">Consultation rate: {professionalData?.profile.compensation}</button>
                   </div>
                   <div>
                       <button className="btn btn-primary mt-3 fw-bold" onClick={() => navigate('/meetings')}>Setup a meeting</button>
                   </div>
               </div>
           </div>
            <hr/>
            <div className="w-75 mobile-full-width">
                <div className="row mt-5">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <p>About the company</p>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        <p className="">{professionalData?.profile.about_me}</p>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <p>Expertise</p>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        {professionalData?.profile.skills.map(skill =>
                            <div>
                                <p className="mb-2 mt-3">{skill?.name}</p>
                                <div>
                                    {skill.skills.map(item => <span className="badge badge-pill badge-success m-2">M{item?.name}</span>)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <hr/>
            <div className="mt-5 w-75 mobile-full-width">
                <Spin spinning={loading}>
                    <label htmlFor="message" className='fw-bold'>Write it to them</label>
                    <textarea id="message" cols="20" rows="6" className='form-control' onChange={e => setMessage(e.target.value)}>{message}</textarea>
                    <button className="btn btn-primary mt-3" onClick={sendMessage}>Send Message</button>
                </Spin>
            </div>
        </div>
    );
};

export default RecommendedProfessinalProfilePage; 