import base_url from "./baseUrl";
import {logoutUser, notifyUser} from "./general";
import secureLocalStorage from "react-secure-storage";

//sample request to make
export let serverRequest = (type,param, data) => {

    let URL = base_url;

    return new Promise((resolve, reject) => {
        fetch(URL+param,{
            method: type,
            headers:{
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${secureLocalStorage.getItem('loggedInToken')}`,
            },
            body: JSON.stringify(data),
            // credentials: 'include',

        })
            .then((response) => {
                if (response.status === 401){
                    logoutUser()
                }
                return response.json()
            })
            .then((resp) => {
                resolve(resp)
            })
            .catch((error) => {
                reject(error)
            }).finally(()=>{
                // notifyUser('error','Sorry problem getting data','Error')
        })
    });
}

//this is for uploading the files.
export let serverRequestNoHeaders = (type,param, data) => {

    let URL = base_url;

    return new Promise((resolve, reject) => {
        fetch(URL+param,{
            method: type,
            body: data,
            headers:{
                "Authorization": `Bearer ${secureLocalStorage.getItem('loggedInToken')}`,
            }
        })
            .then((response) => response.json())
            .then((resp) => {
                resolve(resp)
            })
            .catch((error) => {
                reject(error)
            })
    });
}