import React from "react";
import analysisIcon from "../../../assets/linechartAnalysis.png";
import rankingIcon from "../../../assets/medal.png";
import cactoltvratio from "../../../assets/cactoltvratio.PNG";
import breakeventimeline from "../../../assets/breakeventimeline.PNG";
import monthlyrevenue from "../../../assets/monthlyrevenue.PNG";
import salescycle from "../../../assets/salescycle.PNG";
import projectedAnnualGrowth from "../../../assets/projectedAnnualGrowth.PNG";


const FInancialMetricsForInvestmentReadiness = ({ sectionData = {}, analysis = [], rankingData = "Ranking Value not available", completeReportData = {} }) => {
    var ranking_class_name = "#4B4B4B";
    if (rankingData === "Below Average") {
        ranking_class_name = "#F64C4C";
    } else if (rankingData === "Average") {
        ranking_class_name = "#FE9B0E";
    } else if (rankingData === "Above Average") {
        ranking_class_name = "#47B881";
    } else {
        ranking_class_name = "#4B4B4B";
    }

    let analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    if (Array.isArray(analysis)) {
        analysisSectionTextDescription = analysis[2] ? analysis[2] : "Analysis is not available at this moment for this metric.";
    }
    else if (typeof analysis === "object" && analysis !== null && !Array.isArray(analysis)) {
        analysisSectionTextDescription = analysis["Analysis"];
    } else {
        analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    }

    const assessment = completeReportData?.assessment || {};

    const formatCurrency = (numberString) => {
        const number = parseInt(numberString, 10); // Convert string to number
        if (isNaN(number)) {
            return ""; // Return error message if input is not a valid number
        }
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            // Uncomment the following line to display compact notation for large numbers
            // notation: 'compact',
            minimumFractionDigits: 0,  // No fractional part for whole numbers
        });

        return formatter.format(number);
    };


    return (
        <div className="investmentReadinessMarketOpportunitySection">
            <div className="investmentReadinessMarketOpportunitySectionHeader">
                <div className="investmentReadinessMarketOpportunitySectionTitleIndex">04</div>
                <div className="investmentReadinessMarketOpportunitySectionTitleName">FINANCIAL METRICS</div>
            </div>
            <div className="investmentReadinessMarketOpportunitySectionContent">
                <div className="nonGraphicalContentForMarketOpportunity">
                    <div className="analysisContentForMarketOpportunity">
                        <div className="analysisContentTitle">
                            <img src={analysisIcon} alt="Idea" width="15px" height="15px"></img>
                            <div className="analsyisTitleName">Analysis</div>
                        </div>
                        <div className="analysisContentDescription">
                            {analysisSectionTextDescription}
                        </div>
                    </div>
                    <div className="rankingContentForMarketOpportunity">
                        <div className="rankingContentTitle">
                            <img src={rankingIcon} alt="Idea" width="20px" height="20px"></img>
                            <div className="rankingTitleName">Ranking</div>
                        </div>
                        <div className="rankingContentDescription" style={{ width: "max-content" }}>
                            <div style={{ borderColor: ranking_class_name, padding: '10px', border: `2px solid ${ranking_class_name}`, minWidth: '20%',borderRadius: '10px', textAlign: 'center' }}>
                                <span style={{ color: ranking_class_name }}>{rankingData}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="financialMetricsDetailsSection">
                <div className="financialMetricsDetailsSectionOne">
                    <div className="financialMetricOne">
                        <img src={cactoltvratio} alt="Idea" width="50px" height="50px"></img>
                        <div className="financialMetricName">"CAC Vs LTV"</div>
                        <div className="financialMetricValue">{assessment?.customer_acquisition_cost || ""}</div>
                    </div>
                    <div className="gapBetweenFinancialMetrics">

                    </div>
                    <div className="financialMetricTwo">
                        <img src={breakeventimeline} alt="Idea" width="50px" height="50px"></img>
                        <div className="financialMetricName">Average monthly revenue</div>
                        <div className="financialMetricValue">${assessment?.average_monthly_revenue}</div>
                    </div>
                    <div className="gapBetweenFinancialMetrics"></div>
                    <div className="financialMetricThree">
                        <img src={monthlyrevenue} alt="Idea" width="50px" height="50px"></img>
                        <div className="financialMetricName">Break-even Expectations</div>
                        <div className="financialMetricValue">{assessment?.break_even_projection || ""}</div>
                    </div>
                </div>
                <div className="financialMetricsDetailsSectionTwo">
                    {/* <div className="gapBetweenFinancialMetrics"></div> */}
                    <div className="financialMetricTwo">
                        <img src={projectedAnnualGrowth} alt="Idea" width="50px" height="50px"></img>
                        <div className="financialMetricName">Projected Annual Growth Rate (PAGR)</div>
                        <div className="financialMetricValue">{assessment?.projected_annual_growth_five_years || ""}</div>
                    </div>

                </div>
            </div>
            <div className="rankingContentForMarketOpportunity" style={{ marginTop: "3%" }}>
                <div className="rankingContentTitle">
                    <div className="rankingTitleName">Requires Remediation</div>
                </div>
                <div className="analysisContentDescription">
                    {sectionData && (
                        <>
                            {sectionData["Needs fixing"] ? `Yes, ${sectionData["Reason"]}` : 'No remediation required.'}
                        </>
                    )}
                </div>
            </div>
            <div className="investmentReadinessSectionDivider"></div>
        </div>
    );
};

export default FInancialMetricsForInvestmentReadiness;