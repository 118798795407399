import React from "react";
import InitialsAvatar from "react-initials-avatar";

export const SingleFile = ({ title, file, status }) => {

    const downloadFiles = (fileName, filesUrl) => {
        filesUrl.forEach((item) => {
            const link = document.createElement('a');
            link.href = `https://api.anvillist.com/docs/${item}`;
            link.download = `${fileName}-${item}`;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

  return (
      <div className="file-grey-box p-3">
          <div className="dot-small-card mb-3 float-end" style={{cursor:'pointer'}}>
              {status === 'completed' ?
                  <i className="bi bi-download" onClick={() => downloadFiles(title, file)}/>
                  : <i className="bi bi-upload" onClick={()=> window.location.href = `/data-room/upload?step=${title}`}/>}
          </div>
          <div className='mt-4 mb-3'>
              <p className="fw-bold mb-0">{title}</p>
              <small className="text-black-50" style={{fontSize:'11px'}}>has {file?.length} file{file?.length > 1 ? 's': ''}</small>
          </div>
          <div className="shadow-sm bg-white p-3 rounded" style={{height: '100px'}}>
              <InitialsAvatar name={title} />
          </div>
      </div>
  )
}