// import Dashboard from "./pages/Dashboard";
// import AssessmentForm from "./pages/AssessmentForm";
import React, {useEffect} from "react";
import SignUpPageComponent from "./pages/SignUpPage";
import {getUserToken} from "./Utils/general";

function App() {
    useEffect(() => {
        if (getUserToken()){
            window.location.href = '/mainDashboard'
        }else{
            window.location.href = '/login'
        }
    },[])
  return (
    <div className="App">
      {/* <Dashboard /> */}
      {/* <AssessmentForm /> */}
      <SignUpPageComponent />
    </div>
  );
}

export default App;
