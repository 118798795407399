import {notification} from "antd";
import secureLocalStorage from "react-secure-storage";

export function notifyUser(
    type = "success",
    message,
    title = "Notification",
    placement = "bottomRight"
) {
    if (type == "error") {
        notification.error({
            message: title,
            description: message,
            placement: placement,
        });
    } else {
        notification.success({
            message: title,
            description: message,
            placement: placement,
        });
    }
}


export function logoutUser() {
    secureLocalStorage.removeItem('loggedInToken')
    window.location.href = "/login";
}

export function getUserToken(){
    return secureLocalStorage.getItem('loggedInToken') ?? null;
}

export function getUserDetails(){
    return JSON.parse(secureLocalStorage.getItem('loggedInUser'));
}

export function isSubscribed(){
    return JSON.parse(secureLocalStorage.getItem('loggedInUser'))?.isSubscriptionActive;
}

export let currencyFormatter = (num) => {
    let split = "";
    if (num) {
        split = parseFloat(num).toFixed(2).toString().split(".");
        split[0] = split[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        split = split.join(".");

        return split === 0 ? 0 : split;
    } else {
        return 0;
    }
};