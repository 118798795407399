import React from "react";
import { message, Upload } from 'antd';
import {Progress} from "antd";

const { Dragger } = Upload;

export const UploadFiles = ({ documents}) => {

    const uploadProps = {
        name: 'file',
        multiple: true,
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

  return (
      <div className=''>
          <p className="fw-bold lead">Upload the documents to attract the investors</p>
          <div className="progress-box">
              <Progress percent={30} showInfo={false} strokeColor='#6bc497' size={{height: 18}}/>
          </div>
          <div className="row mt-3 mb-5">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <button className="btn btn-white shadow fw-bold p-2">Back</button>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <button className="btn btn-white shadow fw-bold float-lg-end float-md-end p-2">Save & Exit</button>
              </div>
          </div>
          <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  {documents.map((item, index) =>
                      <p className={`d-flex mb-0 p-3 ${item.active ? 'fw-bold' :  ''}`} key={item.key}><div className={`${item.active ? 'number-circle-active' : 'number-circle'}`}>{index+1}</div>{item.name}</p>
                  )}
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="w-75 mx-auto mobile-full-width">
                      <p className="lead fw-bold">Pitch Deck</p>
                      <p className="fw-bold">Do you have a Pitch Deck outlining the business model, value proposition, market opportunity, and traction?</p>

                      <div className="mt-4">
                          <Dragger {...uploadProps}>
                              <p className="ant-upload-drag-icon">
                                  <i className='bi bi-upload'/>
                              </p>
                              <p className="ant-upload-text">Click or drag file to this area to upload</p>
                              <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                          </Dragger>
                      </div>
                      <div className="w-75 mx-auto" style={{paddingTop:'10%'}}>
                          <button className="btn btn-dark mobile-full-width w-100">Continue</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}