import React from "react";
import MainButton from "../v1/MainButton";

export const FreshAccessUser = () => {
  return (
      <div className=''>
        <div className="w-50 mx-auto mobile-full-width center-text-div">
          <h4 className="fw-bold">Welcome to the Data Room</h4>
          <p className="fw-bold mt-4">Data room is your safe space to upload and store documents but with a purpose to attract investors for seeking funding.</p>
          <MainButton classType='btn btn-small btn-primary w-50 mt-4 fw-bold' title="Let's get started" hasOnClick={true} onclickFunction={() => localStorage.setItem('newDataRoomUser', 'no')}/>
        </div>
      </div>
  )
}