import React, {useEffect, useState} from "react";
import {Drawer, Spin} from "antd";
import {ProfileMiniCards} from "./ProfileMiniCards";
import {serverRequest, serverRequestNoHeaders} from "../../Utils/server_request";
import {currencyFormatter, getUserDetails, notifyUser} from "../../Utils/general";
import {ProfilePieChart} from "./ProfilePieChart";

export const ProfilePage = () => {

    const [openDrawer, setOpenDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasProfile, setHasProfile] = useState(false);
    const [fieldPairs, setFieldPairs] = useState([{ percentage: "", description: "" }]);
    const [formData, setFormData] = useState({ fundingType: '', fundingAmount: '', equity: '', debt: '',
        repay: '', logo: '', problemSolving: '', proposedSolution: '', whoSolvingFor: '',current_valuation: '', skill_and_position: '',
        team_size: '',unit_cost: '',ltv: '',cac: '',userRetention: '',tam: '',sam: '',active_users: '',annual_revenue: '', growth: '', break_even_period: '', employee_number: '' });

    useEffect(() => {
        getBusinessProfile()
    },[])

    const getBusinessProfile = () => {
      serverRequest('GET', 'business/profile/created-by-me').then(result => {
          if (result.data){
              setFormData(replaceNulls(result.data))
              setHasProfile(true)
          }
      })
    }

    // Replace null values with empty strings
    const replaceNulls = (obj) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            if (value === null) {
                acc[key] = ""; // Replace null with empty string
            } else if (Array.isArray(value)) {
                // Handle arrays
                acc[key] = value.map((item) =>
                    typeof item === "object" && item !== null ? replaceNulls(item) : item
                );
            } else if (typeof value === "object" && value !== null) {
                // Handle nested objects
                acc[key] = replaceNulls(value);
            } else {
                acc[key] = value; // Leave other values as they are
            }
            return acc;
        }, {});
    };

    const toggleDrawer = () => setOpenDrawer(prevState => !prevState)

    // Add a new input field
    const handleAddField = () => { setFieldPairs([...fieldPairs, { percentage: "", description: "" }]); }

    // Update the value of a specific field
    const handleInputChange = (index, field, value) => {
        const newFieldPairs = [...fieldPairs];
        newFieldPairs[index][field] = value;
        setFieldPairs(newFieldPairs);
    };

    const handleFileChange = (e) => {
        const formPayload = new FormData();
        const file = e.target.files[0];

        if (file && file.size > 2 * 1024 * 1024) { // 2MB limit
            notifyUser('error','File size should be less than 2MB.');
            return;
        }

        formPayload.append("file", file);
        serverRequestNoHeaders('POST', 'file-upload', formPayload).then(result => {
            if (result?.success){
                setFormData({...formData, logo: result.file })
            }
        }).catch((error) => {
            console.error('Error:', error);
        })
    };

    const toggleLoading = () => setLoading(prevState => !prevState)

    const changeHandler = (event) => { setFormData({...formData, [event.target.name]: event.target.value }); }

    const saveProfile = () => {
        // Check if all fields are filled
        // const allFieldsFilled = Object.values(formData).every((value) => value.trim() !== '');

        // if (!allFieldsFilled) {
        //     notifyUser('error','Please fill in all required fields.', 'Validation Error');
        //     return;
        // }
        console.log(formData)
        if (formData?.fundingType.trim() !== ''){
            if (formData?.fundingAmount.trim() === ''){
                notifyUser('error','Funding amount is required!', 'Validation Error');
                return;
            }
            let _sum = 0;
            fieldPairs.map(item => {
                _sum += parseInt(item.percentage)
            })
            if (_sum !== 100){
                notifyUser('error','Split funding total should amount to 100%', 'Validation Error');
                return;
            }

            if (formData?.fundingType && formData?.fundingType === 'Mezzanine'){
                if (formData?.debt.trim() === '' || formData?.equity.trim() === '' || formData?.repay.trim() === ''){
                    notifyUser('error','Debt, Equity and Repay period are all required!', 'Validation Error');
                    return;
                }
            }

            if (formData?.fundingType && formData?.fundingType === 'Equity'){
                if (formData?.equity.trim() === '' || formData?.fundingAmount.trim() === ''){
                    notifyUser('error','Equity and funding amount fields are  required!', 'Validation Error');
                    return;
                }
            }

            if (formData?.fundingType && formData?.fundingType === 'Debt'){
                if (formData?.repay.trim() === '' || formData?.fundingAmount.trim() === ''){
                    notifyUser('error','Repay  period and funding amount fields are required!', 'Validation Error');
                    return;
                }
            }
        }

        toggleLoading()
        serverRequest(`${hasProfile ? 'PUT': 'POST'}`,`${hasProfile ? `business/profile/${formData.id}`: 'business/profile/create'}`, {...formData, splitFunding: fieldPairs})
            .then(result => {
                if (result.success){
                    notifyUser('success','Business profile created successfully!', 'Success');
                    getBusinessProfile()
                    toggleLoading()
                    toggleDrawer();
                }else{
                    toggleLoading()
                    notifyUser('error','Problem creating business profile', 'Error');
                }
            }).catch(error =>{
                toggleLoading()
                notifyUser('error',error.message, 'Validation Error');
            })
    }

    return (
      <div>
          {localStorage.getItem('completeProfile') && localStorage.getItem('completeProfile') === 'TRUE'  ?
              <div className="alert alert-warning alert-dismissible fade show" role="alert">
                  <strong><i className="bi bi-info-circle"/></strong> Please complete your profile while we process your report.
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => localStorage.removeItem('completeProfile')} />
              </div> : ''}
          <div className="row mb-5 align-items-center">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  {hasProfile && formData?.logo ?
                      <img src={`https://api.anvillist.com/docs/${formData?.logo }`} alt="logo" className="img-fluid" width='120px'/>
                      :
                      <div className="profile-avatar-box" align='center'/>
                  }
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <p className="lead fw-bold"><i className="bi bi-shield-fill-check"/> {getUserDetails()?.business_name.toUpperCase()}</p>
                  {/*<p><i className="bi bi-geo"/>Ghana</p>*/}
                  <ProfileMiniCards icon='bi-gear' heading='Industry' subtext={getUserDetails()?.industry} />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <p className=""><i className="bi bi-envelope"/> Email: {getUserDetails()?.email}</p>
                  <p><i className="bi bi-phone"/>Contact: {getUserDetails()?.phone}</p>
              </div>
          </div>
          <div className="row align-items-center mb-5">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  {/*<ProfileMiniCards icon='bi-gear' heading='Industry' subtext={getUserDetails()?.industry} />*/}
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <button className="btn btn-primary fw-bold" onClick={toggleDrawer}>{hasProfile ? 'Edit Profile': 'Create Profile'}</button>
              </div>
          </div>
          <div className="row mb-5" style={{ borderLeft: '4px solid #00b0e8',paddingLeft:'15px'}}>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-5">
                  <ProfileMiniCards icon='bi-lightbulb' heading='Problem your company solves for clients' subtext={hasProfile && formData?.problemSolving ? formData?.problemSolving : 'N/A'} />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-5">
                  <ProfileMiniCards icon='bi-gear' heading='Proposed solution' subtext={hasProfile && formData?.proposedSolution ? formData?.proposedSolution : 'N/A'} />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-5">
                  <ProfileMiniCards icon='bi-people' heading='Who are you solving the problem for' subtext={hasProfile && formData?.whoSolvingFor ? formData?.whoSolvingFor : 'N/A'} />
              </div>
          </div>
          <p className="fw-bold lead">Investment needs and type of investment</p>
          <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <p className="fw-bold">Funding Amount: {hasProfile && formData?.fundingAmount ? `$${currencyFormatter(formData?.fundingAmount)}` : 'N/A'}</p>
                  {hasProfile && (formData?.fundingType === 'Equity' || formData?.fundingType === 'Mezzanine') ?
                    <>
                        <p className="fw-bold">Equity: {hasProfile && formData?.equity ? `${formData?.equity}%` : 'N/A'}</p>
                    </>
                      :''}
                  {hasProfile && formData?.fundingType === 'Mezzanine' ?
                      <>
                          <p className="fw-bold">Debt: {hasProfile && formData?.debt ? `${formData?.debt}%` : 'N/A'}</p>
                      </>
                      :''}
                  {hasProfile && (formData?.fundingType === 'Debt'|| formData?.fundingType === 'Mezzanine') ?
                    <>
                        <p className="fw-bold">Repayment Time: {hasProfile && formData?.repay ? formData?.repay : 'N/A'}</p>
                    </>
                      :''}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  {hasProfile && formData?.splitFunding.length  > 0 ? <p className="fw-bold">Split Funding</p> : ''}
                  <div >
                      <ProfilePieChart splitFunding={hasProfile && formData?.splitFunding.length  > 0 ? formData?.splitFunding : []} />
                  </div>
              </div>
          </div>
          <div className='mt-5 profile-sections-box' style={{ borderLeft: '4px solid #00b0e8',paddingLeft:'15px'}}>
            <p className="fw-bold">Target Market</p>
              <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width" style={{ border: '1px solid #00b0e8'}}>
                          <small>TAM</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.tam ? `$${currencyFormatter(formData?.tam)}` : 'N/A'}</p>
                          <p className='mb-0'>Total addressable market size of your target audience</p>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width" style={{ border: '1px solid #00b0e8'}}>
                          <small>SAM</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.sam ? `$${currencyFormatter(formData?.sam)}` : 'N/A'}</p>
                          <p className='mb-0'>Serviceable addressable market size of your target audience</p>
                      </div>
                  </div>
              </div>
          </div>
          <div className='mt-5 profile-sections-box' style={{ borderLeft: '4px solid #79ed59',paddingLeft:'15px'}}>
            <p className="fw-bold">Traction</p>
              <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width profile-sections-box-detail" style={{ border: '1px solid #79ed59'}}>
                          <small>Active Users</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.active_users ? formData?.active_users : '0'} Users</p>
                          <small>Active customers you have</small>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width profile-sections-box-detail" style={{ border: '1px solid #79ed59'}}>
                          <small>Growth</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.growth ? formData?.growth : '0'}</p>
                          <p className='mb-0'>Month-to-Month Growth</p>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width profile-sections-box-detail" style={{ border: '1px solid #79ed59'}}>
                          <small>User Retention</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.userRetention ? `${formData?.userRetention}%` : 'N/A'}</p>
                          <small>customer retention rate</small>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width profile-sections-box-detail" style={{ border: '1px solid #79ed59'}}>
                          <small>Break even period</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.break_even_period ? formData?.break_even_period : 'N/A'}</p>
                          <p className='mb-0'>Estimated break-even period</p>
                      </div>
                  </div>
              </div>
          </div>
          <div className='mt-5 profile-sections-box' style={{ borderLeft: '4px solid #F2C425',paddingLeft:'15px'}}>
            <p className="fw-bold">Finance</p>
              <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width profile-sections-box-detail" style={{ border: '1px solid #F2C425'}}>
                          <small>CAC</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.cac ? `$${currencyFormatter(formData?.cac)}` : 'N/A'}</p>
                          <small>Cost to acquire each customer </small>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width profile-sections-box-detail" style={{ border: '1px solid #F2C425'}}>
                          <small>LTV</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.ltv ? formData?.ltv : 'N/A'}</p>
                          <small>Average duration a customer stays with your business before leaving</small>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width profile-sections-box-detail" style={{ border: '1px solid #F2C425'}}>
                          <small>Unit Cost</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.unit_cost ? `$${currencyFormatter(formData?.unit_cost)}` : 'N/A'}</p>
                          <small>Cost for you to produce your product/service for your customers</small>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width profile-sections-box-detail" style={{ border: '1px solid #F2C425'}}>
                          <small>Current Annual Revenue</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.annual_revenue ? `$${currencyFormatter(formData?.annual_revenue)}` : 'N/A'}</p>
                          <small>How much does your business make annually</small>
                      </div>
                  </div>
              </div>
          </div>
          <div className='mt-5 profile-sections-box' style={{ borderLeft: '4px solid #EB81FFFF',paddingLeft:'15px'}}>
              <p className="fw-bold">Team</p>
              <div className="row mb-4">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width profile-sections-box-detail" style={{ border: '1px solid #EB81FFFF'}}>
                          <small>Founding Team Size</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.team_size ? formData?.team_size : 'N/A'}</p>
                          <small>Size of the founding team</small>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width profile-sections-box-detail" style={{ border: '1px solid #EB81FFFF'}}>
                          <small>Employees</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.employee_number ? formData?.employee_number : 'N/A'}</p>
                          <small>Employees size of the business</small>
                      </div>
                  </div>
              </div>
              <small className="">Skills and Position</small>
              <p className='mb-1'>{hasProfile && formData?.skill_and_position ? formData?.skill_and_position : 'N/A'}</p>
          </div>
          <div className='mt-5 profile-sections-box' style={{ borderLeft: '4px solid #ED853FFF',paddingLeft:'15px'}}>
              <p className="fw-bold">Current Valuation</p>
              <div className="row mb-4">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                      <div className="p-2 rounded mobile-full-width profile-sections-box-detail" style={{ border: '1px solid #ED853FFF'}}>
                          <small>Valuation</small>
                          <p className='fw-bold mb-2'>{hasProfile && formData?.current_valuation ? `$${currencyFormatter(formData?.current_valuation)}` : 'N/A'}</p>
                          <small>Current valuation of your company</small>
                      </div>
                  </div>
              </div>
          </div>

          <Drawer
              title="Basic Drawer"
              placement='right'
              width='700'
              closable={true}
              onClose={toggleDrawer}
              open={openDrawer}
              destroyOnClose={true}>
              <Spin spinning={loading}>
                  <div className="p-4 mt-5">
                      <div className="form-group mb-4">
                          <label className="mb-0"><i className="bi bi-lightbulb"/> Problem your company solves for clients?</label>
                          <textarea name="problemSolving" rows="3" className='form-control' onChange={changeHandler} placeholder='Tell us more'>{formData.problemSolving}</textarea>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0"><i className="bi bi-lightbulb"/>  How are you solving the problem?</label>
                          <textarea name="proposedSolution" rows="3" className='form-control' onChange={changeHandler} placeholder='Tell us more'>{formData.proposedSolution}</textarea>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0"><i className="bi bi-people"/>  Describe your target audience</label>
                          <textarea name="whoSolvingFor" rows="3" className='form-control' onChange={changeHandler} placeholder='Who are you solving the problem for?'>{formData.whoSolvingFor}</textarea>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">Type of funding</label>
                          <select name="fundingType" id="" className="form-control" onChange={changeHandler}>
                              <option>==== Select funding type =====</option>
                              <option value="Equity" selected={formData.fundingType === 'Equity'}>Equity</option>
                              <option value="Debt" selected={formData.fundingType === 'Debt'}>Debt</option>
                              <option value="Mezzanine" selected={formData.fundingType === 'Mezzanine'}>Mezzanine</option>
                          </select>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">Funding Amount</label>
                          <input type="number" className="form-control" placeholder='$1,000,000' onChange={changeHandler} value={formData.fundingAmount} name='fundingAmount'/>
                      </div>
                      {formData.fundingType === 'Equity' || formData.fundingType === 'Mezzanine' ?
                          <div className="form-group mb-4">
                              <label className="mb-0">Equity %</label>
                              <input type="number" className="form-control" placeholder='%' onChange={changeHandler} value={formData.equity} name='equity'/>
                          </div>
                          : ''}
                      {formData.fundingType === 'Mezzanine' ?
                          <div className="form-group mb-4">
                              <label className="mb-0">Debt %</label>
                              <input type="number" className="form-control" placeholder='%' onChange={changeHandler} value={formData.debt} name='debt'/>
                          </div>
                          : ''}

                      {formData.fundingType === 'Debt' || formData.fundingType === 'Mezzanine' ?
                      <div className="form-group mb-4">
                          <label className="mb-0">Time to repay</label>
                          <input type="text" className="form-control"  placeholder='12 months' onChange={changeHandler} value={formData.repay} name='repay'/>
                      </div>: ''}
                      <div className="mb-4">
                          <label className="mb-0">Split Funding</label>
                          {fieldPairs.map((pair, index) => (
                              <div className="row align-items-center mb-2" key={index}>
                                  <div className="col-5">
                                      <span>Percentage: </span>
                                      <input type="number" className="form-control" placeholder="%" value={pair.percentage}
                                             onChange={(e) => handleInputChange(index, "percentage", e.target.value)}/>
                                  </div>
                                  <div className="col-7">
                                      <span>For: </span>
                                      <input type="text" className="form-control" placeholder="Marketing" value={pair.description}
                                             onChange={(e) => handleInputChange(index, "description", e.target.value)}/>
                                  </div>
                              </div>
                          ))}
                          <small className='float-end mt-2' onClick={handleAddField} style={{ cursor: "pointer" }}><i className="bi bi-plus-circle"/> Add More</small>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0"><i className="bi bi-file-pdf"/>  Business Logo</label>
                          <input type="file" className="form-control" name='logo' accept="image/*" onChange={handleFileChange}/>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">What is your customer retention rate (%) ?</label>
                          <input type="number" className="form-control" onChange={changeHandler} value={formData.userRetention} name='userRetention'/>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">Describe your founding team and the unique skills each member contributes.</label>
                          <textarea className="form-control" onChange={changeHandler} name='skill_and_position'>{formData.skill_and_position}</textarea>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">What is the current valuation of your company?</label>
                          <input type="number" className="form-control" onChange={changeHandler} value={formData.current_valuation} name='current_valuation'/>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">How much does it cost you to acquire each customer?</label>
                          <input type="number" className="form-control" onChange={changeHandler} value={formData.cac} name='cac'/>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">What is the average duration a customer stays with your business before leaving?</label>
                          <input type="text" className="form-control" onChange={changeHandler} value={formData.ltv} name='ltv' placeholder='2 years'/>
                          <small>Should be in either (days | months | years)</small>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">How much does it cost you to produce your product/service for your customers?</label>
                          <input type="number" className="form-control" onChange={changeHandler} value={formData.unit_cost} name='unit_cost'/>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">What is the size of your co-founders?</label>
                          <input type="number" className="form-control" onChange={changeHandler} value={formData.team_size} name='team_size'/>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">What is the total addressable market size of your target audience?</label>
                          <input type="number" className="form-control" onChange={changeHandler} value={formData.tam} name='tam'/>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">What is the serviceable addressable market size of your target audience?</label>
                          <input type="number" className="form-control" onChange={changeHandler} value={formData.sam} name='sam'/>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">How many active customers do you have?</label>
                          <input type="number" className="form-control" onChange={changeHandler} value={formData.active_users} name='active_users'/>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">How much does your business make annually?</label>
                          <input type="number" className="form-control" onChange={changeHandler} value={formData.annual_revenue} name='annual_revenue'/>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">What is your monthly customer growth (%)</label>
                          <input type="number" className="form-control" onChange={changeHandler} value={formData.growth} name='growth'/>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">What is your estimated break-even period?</label>
                          <input type="text" className="form-control" onChange={changeHandler} value={formData.break_even_period} name='break_even_period' placeholder='6 months'/>
                          <small>Should be in either (days | months | years)</small>
                      </div>
                      <div className="form-group mb-4">
                          <label className="mb-0">How many employees does your business have?</label>
                          <input type="number" className="form-control" onChange={changeHandler} value={formData.employee_number} name='employee_number'/>
                      </div>
                      <div align='center'>
                          <button className="btn btn-small btn-primary w-25 mt-5" onClick={saveProfile}>Save Profile</button>
                      </div>
                  </div>
              </Spin>
          </Drawer>
      </div>
  )
}