import React, {useEffect, useState} from "react";
import cooperationImg from '../../assets/cooperation1.png'
import noDataImg from '../../assets/noDataAvailable.png'
import {serverRequest} from "../../Utils/server_request";
import { useNavigate } from 'react-router-dom';
import {formatToLocalDate} from "../FormattedDate";
import {Pagination} from "./Pagination";
import {notifyUser} from "../../Utils/general";
import {Spin} from "antd";

export const RecommendationPage = () => {

  const navigate = useNavigate();
  const [reports, setReports] = useState([])
  const [recommendations, setRecommendations] = useState([])
  const [meta, setMeta] = useState({});
  const [location, setLocation] = useState(null);
  const [budget, setBudget] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getReports()
    getRecommendation()
  },[])

  const getReports = () => {
    toggleLoading()
    serverRequest('GET', 'all-business-assessments').then(result => {
      if (result?.data.length > 0){
        setReports(result.data)
        toggleLoading()
      }
    }).catch(error => {
      toggleLoading()
      notifyUser('error', error.message, 'Loading failed')
    })
  }

  const handleViewAssessmentReportButtonClick = (assessmentReportData, completeReportData) => {
    navigate('/viewAssessmentReport', {
      state: {
        assessmentReportData: assessmentReportData,
        completeReportData: completeReportData
      }
    });
  };

  const handleProfessionalClick = (professionalData) => {
    navigate('/professional-profile', {
      state: {
        professionalData: professionalData
      }
    });
  };

  const toggleLoading = () => setLoading(prevState => !prevState)

  const getRecommendation = (page = 1, _location=location, _budget=budget) => {
    let url = `get-recommendations?page=${page}`;
    if (_location){
      url =  url + `&location=${_location}`;
    }
    if (_budget){
      url =  url + `&budget=${_budget}`;
    }
    toggleLoading()
    serverRequest('GET',url).then(result => {
      if (result.data){
        setRecommendations(result.data)
        setMeta(result.meta);
        toggleLoading()
      }
    }).catch(error => {
      toggleLoading()
      notifyUser('error', error.message, 'Loading failed')
    })
  }

  const submitFilter = () => {
    if (location){
      getRecommendation()
    }
    if (budget){
      getRecommendation()
    }
  }

  const clearAll = () => {
    setBudget(null)
    setLocation(null)
    getRecommendation()
  }

  return (
      <div>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="bg-light-blue max-height p-4">
              <div className='row'>
                <div className="col"><p>Filter</p></div>
                <div className="col"><p className='text-danger' style={{textAlign:"end",cursor:'pointer'}} onClick={clearAll}>Clear All</p></div>
              </div>
              <div className="form-group mt-4">
                <label htmlFor="" className='mb-2'>Location</label>
                <input type="text" className="form-control" placeholder='Type a location' value={location} onChange={e => setLocation(e.target.value)}/>
              </div>

              <div className="form-group mt-5">
                <label htmlFor="" className='mb-2'>Budget</label>
                <input type="number" className="form-control" placeholder='$0' value={budget} onChange={e => setBudget(e.target.value)} />
              </div>
              <button className="btn btn-primary btn-small mt-5"  onClick={submitFilter}>Filter Results</button>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <Spin spinning={loading}>
              {recommendations.length >  0 ?
                  recommendations.map(professional =>
                      <div className="shadow p-4 bg-white rounded mb-3"  key={professional.id} onClick={() => handleProfessionalClick(professional)} style={{cursor:"pointer"}}>
                        <div className="row">
                          <div className="col-10">
                            <div className="row">
                              <div className="col-2"><img className="img-fluid" src={cooperationImg}/></div>
                              <div className="col-10">
                                <p className="fw-bold mb-1">{professional.rep_business_name}</p>
                                <small><i className="bi bi-geo"/> {professional?.profile.location}</small>
                              </div>
                            </div>
                            <p className='mt-3'>{professional?.profile.about_me}</p>
                            <div className='skill-tags'>
                              {professional?.profile?.skills.map(skill => <span className="badge badge-pill badge-success m-2" key={skill.id}>{skill?.name}</span>)}
                            </div>
                          </div>
                          <div className="col-2">
                            <button className="btn btn-dark btn-small">{professional?.profile.compensation}</button>
                          </div>
                        </div>
                      </div>
                  )
                  :
                  <div align='center'>
                    <div>
                      <img src={noDataImg} alt="No data" className='img-fluid' width='90px'/>
                    </div>
                    <p className="">No business experts available at the moment!</p>
                  </div>
              }
            </Spin>
            {recommendations.length > 10 ?
                <Pagination
                    currentPage={meta.current_page}
                    perPage={meta.per_page}
                    total={meta.total}
                    onPageClick={getRecommendation}/>
                : ''}
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="shadow max-height p-4">
              <p className="lead fw-bold">Reports</p>
              <Spin spinning={loading}>
              {reports.length > 0 ?
                  reports.map(assessment =>
                      <div className="shadow-sm p-3 mb-3" key={assessment?.assessment.id}>
                        <div>
                          <p className='mb-1'>{assessment.name} | <span className="text-success fw-bold">{!assessment.reports[0].report ? 'Pending Report': 'Report Ready'}</span></p>
                          <small>{formatToLocalDate(assessment?.assessment.createdAt)}</small>
                        </div>
                        <button className="btn btn-dark btn-small mt-3" disabled={assessment.reports[0].report == null} onClick={() => handleViewAssessmentReportButtonClick(assessment.reports, assessment)}>{!assessment.reports[0].report ? 'Processing Report': 'View Report'}</button>
                      </div>
                  )
              :
                  <div align='center'>
                    <div>
                      <img src={noDataImg} alt="No data" className='img-fluid' width='90px'/>
                    </div>
                    <button className="btn btn-primary btn-small mt-3" onClick={()=> window.location.href = '/assessmentForm'}>Take an assessment</button>
                  </div>
              }
              </Spin>
            </div>
          </div>
        </div>
      </div>
  )
}