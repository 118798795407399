import React from "react";
import MainNavbarForDashboard from "../components/navbarComponents/NavbarForMainDashbaord";
import AssessmentFormMainScreen from "../components/AssessmentFormMainScreen";
import { useLocation } from "react-router-dom";
import "../css/dashboard.css";

const isNavbarForDashboard = true;

const AssessmentForm = () => {
  const user_details = JSON.parse(sessionStorage.getItem("user_details"));

  return (
    <div className="">
        <AssessmentFormMainScreen user_details={user_details}/>
    </div>
  );
};

export default AssessmentForm;
