import React from "react";
import strengthIconForBenchmark from "../../../assets/strengthIconForBenchmark.png";
import bulbIconForIndustryStandard from "../../../assets/bulbIconForIndustryStandard.png";
import BenchmarkHowToGetThereContainers from "./BenchmarkHowToGetThereContainers";
import belowAverageMedalIcon from "../../../assets/belowAverageRankingIcon.png";
import averageMedalIcon from "../../../assets/averageRankingIcon.png";
import aboveAverageRankingIcon from "../../../assets/aboveAverageRankingIcon.png";
import benchmarkSummaryAspectIcon from "../../../assets/benchmarkSummaryAspectIcon.png";
import benchmarkSummaryRankingIcon from "../../../assets/benchmarkSummaryRankingIcon.png"
import { safeGetFirstPart, safeGetSecondPart } from "./BenchmarksSectionHelperFunctions";

import "../../../css/salesCycleForFinancialMetricsInBenchmark.css";

const FinancialMetricsForBenchmarks = ({ sectionData }) => {
    const percentage = 60;
    const inlineStyles = {
        background: `conic-gradient(#396AFC 0% ${percentage}%, #2948FF 0% ${percentage}%, #ffffff ${percentage}% 100%)`,
    };
    const current_state_first_value = (sectionData && sectionData["company_standard"] && sectionData["company_standard"]["CAC vs CLTV"]) ? sectionData["company_standard"]["CAC vs CLTV"] : "";
    const current_state_second_value = (sectionData && sectionData["company_standard"] && sectionData["company_standard"]["CAC vs CLTV"]) ? sectionData["company_standard"]["CAC vs CLTV"] : "";
    const industry_standard_first_value = (sectionData && sectionData["industry_standard"] && sectionData["industry_standard"]["CAC vs CLTV"]) ? sectionData["industry_standard"]["CAC vs CLTV"] : "";
    const industry_standard_second_value = (sectionData && sectionData["industry_standard"] && sectionData["industry_standard"]["CAC vs CLTV"]) ? sectionData["industry_standard"]["CAC vs CLTV"] : "";
    const section_ranking = (sectionData && sectionData["ranking"]) ? sectionData["ranking"] : "";
    const section_recommendation = (sectionData && sectionData["recommendation"]) ? sectionData["recommendation"] : "";

    var section_ranking_font_color = "";
    if (section_ranking === "Below Average") {
        section_ranking_font_color = "belowAvergaeRankingDescription";
    } else if (section_ranking === "Average") {
        section_ranking_font_color = "avergaeRankingDescription";
    } else if (section_ranking === "Above Average") {
        section_ranking_font_color = "aboveAverageRankingDescription";
    } else {
        section_ranking_font_color = "defaultTextColor";
    }

    return (
        <div className="benchmarkSectionContainer">
            <div className="sectionTitleAndBenchmarkEvalution">
                <div className="benchmarkSectionHeader">04 Financial Metrics</div>
                <div className="benchmarkEvaluationRanking aboveAverageRanking">
                    <div className="medalIcon">
                        {section_ranking == "Below Average" && <img src={belowAverageMedalIcon} width="44px" height="44px" alt="Below Average Icon" />}
                        {section_ranking == "Average" && <img src={averageMedalIcon} width="44px" height="44px" alt="Average Icon" />}
                        {section_ranking == "Above Average" && <img src={aboveAverageRankingIcon} width="44px" height="44px" alt="Above Average Icon" />}
                    </div>
                    <div className="rakingTitleAndDescription">
                        <div className="rankingTitleName">Ranking</div>
                        <div className={`rankingDescription ${section_ranking_font_color}`}>{section_ranking}</div>
                    </div>
                </div>
            </div>

            {/* <div className="anvlistAndindustryStandardContentContainer">
                <div className="anvlistContainer">
                    <img src={strengthIconForBenchmark} width="30px" height="25px" alt="Anvilist Icon" />
                    <div className="anvilistTitle">Anvilist</div>
                    <div className="salesCycleTitleInFinancialMetricsForBenchmark">Sales Cycle</div>
                    <div class="circular-progress-container">
                        <div class="circular-progress" style={{ '--percentage': percentage, '--stroke-color-start': "#EB3349", '--stroke-color-end': "#FF9381" }}>
                            <span class="progress-label">{current_state_first_value}</span>
                        </div>
                        <div class="progress-title">Sales Cycle</div>
                    </div>
                    <div className="anvilistDescription">
                        <div className="competitorsDescriptionContent">
                            <div className="competitorsContentKey">CAC to LTV Ratio:</div>
                            <div className="competitorsContentDescription">{current_state_fourth_value}</div>
                        </div>
                    </div>
                    <div className="monthlyRevenueContainerInFinancialMetricsForBenchmarks">
                        <div className="monthlyRevenueTitleWithinTheContainerForFinancialMetricsForBenchmarks">Monthly Revenue</div>
                        <div className="monthlyRevenueValueWithinTheContainerForFinancialMetricsForBenchmarks">{current_state_third_value}</div>
                    </div>

                    <div className="breakEvenTimlineForFinancialMetricsForBenchmarks">
                        <div className="breakEvenTimlineForFinancialMetricsForBenchmarksTitleAndValue">
                            <div className="breakEvenTimlineForFinancialMetricsForBenchmarksTitle">Break Even Timeline</div>
                            <div className="breakEvenTimlineForFinancialMetricsForBenchmarksValue">{current_state_fourth_value}</div>
                        </div>
                        <div className="breakEvenTimlineForFinancialMetricsForBenchmarkVisualization">
                            <div className="breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainer">
                                <div className="breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainerOuterCircle">
                                    <div className="breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainerInnerCircle" style={inlineStyles}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="industryStandardContainer">
                    <img src={bulbIconForIndustryStandard} width="30px" height="25px" alt="Industry Standard Icon" />
                    <div className="industryStandardTitle">Industry Standard</div>
                    <div className="salesCycleTitleInFinancialMetricsForBenchmark">Sales Cycle</div>
                    <div class="circular-progress-container">
                        <div class="circular-progress" style={{ '--percentage': percentage, '--stroke-color-start': "#0079F2", '--stroke-color-end': "#70B6FF" }}>
                            <span class="progress-label">{industry_standard_first_value.slice(0, 10)}...</span>
                        </div>
                        <div class="progress-title">Sales Cycle</div>
                    </div>
                    <div className="industryStandardDescription">
                        <div className="competitorsDescriptionContent">
                            <div className="competitorsContentKey">CAC to LTV Ratio:</div>
                            <div className="differentiatorsContentDescription">{industry_standard_second_value}</div>
                        </div>
                    </div>
                    <div className="monthlyRevenueContainerInFinancialMetricsForBenchmarks">
                        <div className="monthlyRevenueTitleWithinTheContainerForFinancialMetricsForBenchmarks">Monthly Revenue</div>
                        <div className="monthlyRevenueValueWithinTheContainerForFinancialMetricsForBenchmarks">{industry_standard_third_value}</div>
                    </div>

                    <div className="breakEvenTimlineForFinancialMetricsForBenchmarks">
                        <div className="breakEvenTimlineForFinancialMetricsForBenchmarksTitleAndValue">
                            <div className="breakEvenTimlineForFinancialMetricsForBenchmarksTitle">Break Even Timeline</div>
                            <div className="breakEvenTimlineForFinancialMetricsForBenchmarksValue">{industry_standard_fourth_value}</div>
                        </div>
                        <div className="breakEvenTimlineForFinancialMetricsForBenchmarkVisualization">
                            <div className="breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainer">
                                <div className="breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainerOuterCircle">
                                    <div className="breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainerInnerCircle" style={inlineStyles}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="anvlistAndindustryStandardContentContainer">
                <div className="anvlistContainer">
                    <img src={strengthIconForBenchmark} width="30px" height="25px" alt="Anvilist Icon" />
                    <div className="anvilistTitle">Company Standard</div>
                    <div className="anvilistDescription">
                        <div className="competitorsDescriptionContent">
                            {/* <div className="competitorsContentKey">{safeGetFirstPart(current_state_first_value)}</div>
                            <div className="competitorsContentDescription">{safeGetSecondPart(current_state_first_value)}</div> */}
                            <div className="competitorsContentKey">CAC vs CLTV : </div>
                            <div className="competitorsContentDescription">{(current_state_first_value)}</div>
                        </div>
                        {/*<div className="differentiatorsDescriptionContent">*/}
                        {/*    /!* <div className="differentiatorsContentKey">{safeGetFirstPart(current_state_second_value)}</div>*/}
                        {/*    <div className="competitorsContentDescription">{safeGetSecondPart(current_state_second_value)}</div> *!/*/}
                        {/*    <div className="differentiatorsContentKey">CLTV : </div>*/}
                        {/*    <div className="competitorsContentDescription">{(current_state_second_value)}</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="industryStandardContainer">
                    <img src={bulbIconForIndustryStandard} width="30px" height="25px" alt="Industry Standard Icon" />
                    <div className="industryStandardTitle">Industry Standard</div>
                    <div className="competitorsDescriptionContent">
                        {/* <div className="competitorsContentKey">{safeGetFirstPart(industry_standard_first_value)}</div>
                            <div className="competitorsContentDescription">{safeGetSecondPart(industry_standard_first_value)}</div> */}
                        <div className="competitorsContentKey">CAC vs CLTV : </div>
                        <div className="competitorsContentDescription">{(industry_standard_first_value)}</div>
                    </div>
                    {/*<div className="differentiatorsDescriptionContent">*/}
                    {/*    /!* <div className="differentiatorsContentKey">{safeGetFirstPart(industry_standard_second_value)}</div>*/}
                    {/*        <div className="competitorsContentDescription">{safeGetSecondPart(industry_standard_second_value)}</div> *!/*/}
                    {/*    <div className="differentiatorsContentKey">CLTV : </div>*/}
                    {/*    <div className="competitorsContentDescription">{(industry_standard_second_value)}</div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="howToGetThereHeader">How to get there ?</div>
            <div className="howToGetThereContentContainer">
                <BenchmarkHowToGetThereContainers pointDescription={section_recommendation} />
            </div>
        </div>
    );
};

export default FinancialMetricsForBenchmarks;