import React, {useEffect, useState} from "react";
import {message, Upload} from 'antd';
import {Progress} from "antd";
import {serverRequest, serverRequestNoHeaders} from "../../Utils/server_request";
import {useLocation} from 'react-router-dom';

const {Dragger} = Upload;

export const UploadFiles = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [documents, setDocuments] = useState([]);
    const [activeTab, setActiveTab] = useState("");
    const [currentDocument, setCurrentDocument] = useState({});
    const [stepper, setStepper] = useState(0);

    useEffect(() => {
        const step = params.get("step");
        setActiveTab(step || "");
        getDocuments(step);
    }, [location])

    const getDocuments = async (categoryName) => {
        try {
            const result = await serverRequest("GET", "data-room-documents");
            const updatedDocuments = result.documents.map((doc) => ({
                ...doc,
                active: doc.name === categoryName,
            }));

            setDocuments(updatedDocuments);
            setStepper(categoryName ? updatedDocuments.findIndex((doc) => doc.name === categoryName) : 0);
            setCurrentDocument(categoryName ? updatedDocuments.find((doc) => doc.name === categoryName) : updatedDocuments[0]);
        } catch (error) {
            console.error("Failed to fetch documents:", error);
        }
    };

    const handleContinue = () => {
        setStepper((prevStepper) => {
            const nextStepper = prevStepper + 1;

            if (nextStepper < documents.length) {
                const updatedDocuments = documents.map((doc, index) => ({
                    ...doc,
                    active: index === nextStepper,
                }));

                setDocuments(updatedDocuments);
                setCurrentDocument(updatedDocuments[nextStepper]);
                return nextStepper;
            }
            return prevStepper; // No change if we're at the last step
        });
    };

    const handleBack = () => {
        setStepper((prevStepper) => {
            const newStepper = Math.max(prevStepper - 1, 0); // Prevent stepper from going below 0

            if (newStepper >= 0) {
                const updatedDocuments = documents.map((doc, index) => ({
                    ...doc,
                    active: index === newStepper, // Set the current document as active
                }));

                setDocuments(updatedDocuments);
                setCurrentDocument(updatedDocuments[newStepper]); // Update the current document
            }

            return newStepper; // Return the updated stepper value
        });
    };

    const uploadProps = {
        name: 'file',
        multiple: true,
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        onChange: (info) => {
            const { fileList: newFileList } = info;

        },
    };

    const handleUpload = async (fileList) => {
        for (const file of fileList) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await serverRequestNoHeaders('POST', 'file-upload', formData)
                // console.log('UPLOAD::',response)
                if (response.success){
                    message.success(`${file.name} uploaded successfully.`);
                    await serverRequest('POST', 'set-data-room-file', {category: currentDocument?.name, file_url: response.file})
                }else{
                    message.error(`${file.name} upload failed`);
                }
            } catch (error) {
                message.error(`${file.name} upload failed: ${error.message}`);
            }
        }
    };


    return (
        <div className=''>
            <p className="fw-bold lead">Upload the documents to attract the investors</p>
            <div className="progress-box">
                <Progress percent={(stepper / documents.length) * 100} showInfo={false} strokeColor="#6bc497" size={{ height: 18 }} />
            </div>
            <div className="row mt-3 mb-5">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <button className="btn btn-white shadow fw-bold p-2" onClick={handleBack}>Back</button>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <button className="btn btn-white shadow fw-bold float-lg-end float-md-end p-2" onClick={() => window.location.href = '/data-room'}>Save & Exit</button>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                    {documents.map((item, index) =>
                        <p className={`d-flex mb-0 p-3 ${item.active ? 'fw-bold' : ''}`} key={item.key}>
                            <div
                                className={`${item.active ? 'number-circle-active' : 'number-circle'}`}>{index + 1}</div>
                            {item.name}</p>
                    )}
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                    <div className="w-75 mx-auto mobile-full-width">
                        <p className="lead fw-bold">{currentDocument?.name || "Document Name"}</p>
                        <p className="fw-bold">All document must be outlining the business model, value proposition, market opportunity, traction etc</p>

                        <div className="mt-4">
                            <Dragger {...uploadProps}
                                     beforeUpload={(file, fileList) => {
                                         handleUpload(fileList); // Process the files one by one
                                         return false; // Prevent default upload behavior
                                     }}>
                                <p className="ant-upload-drag-icon">
                                    <i className='bi bi-upload'/>
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                            </Dragger>
                        </div>
                        <div className="w-75 mx-auto" style={{paddingTop: '10%'}}>
                            <button
                                className="btn btn-dark mobile-full-width w-100"
                                onClick={handleContinue}
                                // disabled={stepper === documents.length - 1}
                            >
                                {stepper === documents.length - 1 ? "Finish" : "Continue"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}