import React, { useState, useEffect, useCallback } from "react";
import SignUpPageHeaderComponent from "../components/signUpPageComponents/SignUpPageHeaderComponent";
import AccountCreationComponent from "../components/signUpPageComponents/AccountCreationComponent";
import SignupFormContainerComponent from "../components/signUpPageComponents/SignUpFormContainerComponent";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PasswordValidationComponent from "../formValidations/passwordValidations";
import { Form, notification } from "antd";
import apiDetails from "../assets/apiDetails/apiDetails.json";
import "../css/signUpPage.css";
import secureLocalStorage from "react-secure-storage";

const ResetPasswordPage = () => {
    // const location = useLocation();
    // const { userEnteredEmail } = location.state || {};
    const [apiBodyForAccountVerification, setApiBodyForAccountVerification] = useState({
        "otp": "1QW00e"
    });

    let defaultErrorMessage = "Please fill in all the fields before you continue to the next step";
    const [formFieldErrorMessage, setFormFieldErrorMessage] = useState(defaultErrorMessage);
    const [errorVisible, setErrorVisible] = useState(false);
    const [shouldProceed, setShouldProceed] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({}); // state for field Errors
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();  // Use the Ant Design Form instance
    const navigate = useNavigate();

    const loginComponentHeaderData = {
        "title": "Verify Account",
        "subtitle": "",
        "coming_from": "verifyAccount"
    }

    let itemsToShow = [
        {
            label: "Enter the one time password from your email",
            rules: [
                {
                    required: true,
                    message: 'Enter the one time password from your email.'
                }
            ]
        }
    ];

    const buttonText = "Verify Account";
    const comingFrom = "verifyAccount";
    const otpKey = "Enter the one time password from your email";
    // const userPasswordKey = "Enter new password";
    // const userConfirmPasswordKey = "Confirm new password";

    const openErrorNotification = (notificationParameters) => {
        // console.log("notificationParameters = ", notificationParameters);
        api["error"]({
            message: 'Failed to verify account',
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    const validateEmptyFields = (values) => {
        // new variable for maintaining all the error messages
        let errors = {};
        let isThereAFormError = true;

        const userEnteredOTP = values[otpKey];
        if (userEnteredOTP === null || userEnteredOTP === undefined || userEnteredOTP.length === 0) {
            errors[otpKey] = 'Please enter the otp sent to you in the mail';
            isThereAFormError = false;
            // return false;
        }
        setFormFieldErrorMessage(defaultErrorMessage);
        setFieldErrors(errors);
        return isThereAFormError;
        // return true;
    };

    const handleFormFinish = (values) => {
        // alert("handling form finish for the reset password component");
        //navigate("/login");
        // console.log("userEnteredEmail");
        // console.log(userEnteredEmail);
        // console.log("Form Values :- ", values);
        // validateEmptyFields
        if (!validateEmptyFields(values)) {
            // setErrorVisible(true);
            return;
        }

        setApiBodyForAccountVerification(prevState => ({
            ...prevState,
            "otp": values[otpKey],
        }));

        setShouldProceed(true);
    };

    const apiCallToVerifyAccountUser = useCallback(() => {
        setIsLoading(true);
        const url_domain = apiDetails.domain;
        const version_path = apiDetails.version_path;
        const verify_account = "verify-account";
        const fetch_url = `${url_domain}/${version_path}/${verify_account}`;
        fetch(fetch_url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(apiBodyForAccountVerification)
        })
            .then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    // Handle error here
                    // console.error('Password Reset failed:', errorData);
                    openErrorNotification(errorData);
                    throw new Error('Failed to verify account');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Password Reset successful:', data);
                if (data && data.error) {
                    openErrorNotification(data);
                    throw new Error('Failed to verify account');
                } else {
                    // navigate("/login");
                    localStorage.setItem("anvillist_user_access_token", data.access_token);
                    secureLocalStorage.setItem('loggedInToken', data?.access_token)
                    secureLocalStorage.setItem('loggedInUser', JSON.stringify(data?.data));
                    sessionStorage.setItem("loggedInUserID", data.data.id);

                    const user_details = {
                        "logged_in_user_id": data.data.id,
                        "user_access_token": data.access_token,
                        "user_name": data.data.name,
                    }
                    sessionStorage.setItem("user_details", JSON.stringify(user_details));
                    const assessmentFormStatus_key_for_user = user_details.logged_in_user_id + "_assessment_form_status";
                    const assessment_form_status_value_from_local_storage = localStorage.getItem(assessmentFormStatus_key_for_user);
                    if (assessment_form_status_value_from_local_storage === "completed") {
                        window.location.href = "/recommendation";
                    } else {
                        window.location.href = "/dashboard";
                    }
                }

                // Handle success here (e.g., display a success message, redirect, etc.)
            })
            .catch(error => {
                // console.error('An error occurred while reseting password:', error);
                // Handle general network or other errors here
            })
            .finally(() => {
                setIsLoading(false); // Stop loading regardless of the result
            });
    }, [apiBodyForAccountVerification, navigate]); // Include dependencies that could change

    useEffect(() => {
        if (shouldProceed) {
            // console.log("apiBodyForForgotPassword", apiBodyForAccountVerification);
            apiCallToVerifyAccountUser();
            // setErrorVisible(false);
            setShouldProceed(false); // Reset the flag
        }
    }, [shouldProceed, apiBodyForAccountVerification, apiCallToVerifyAccountUser]); // Run when shouldProceed or currentStep changes


    return (
        <div className="signUpPageContainer">
            {contextHolder}
            <AccountCreationComponent componentHeaderData={loginComponentHeaderData} />
            {errorVisible && (<div className='errorContainer'>{formFieldErrorMessage}</div>)}
            {!errorVisible && (<div style={{ marginTop: "40px" }}></div>)}
            <SignupFormContainerComponent
                form={form}
                formItems={itemsToShow}
                onFinish={handleFormFinish}
                buttonText={buttonText}
                comingFrom={comingFrom}
                fieldErrors={fieldErrors}
                isLoading={isLoading}
            />
            {/* <Link to="/signUp" onClick={(e) => e.stopPropagation()}>Forgot Password?</Link> */}
        </div>
    );

};

export default ResetPasswordPage;