import React, {useState} from "react";
import {getUserDetails} from "../../Utils/general";
import { Input } from 'antd';
import {SingleFile} from "./SingleFile";

export const RoomFileList = ({ documents }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDocs, setFilteredDocs] = useState([]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);

        if (searchTerm.length > 2){
            const filteredDocuments = documents.filter((document) =>
                document.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredDocs(filteredDocuments);
        }else{
            setFilteredDocs([])
        }
    };

  return (
      <div className=''>
        <div className="row align-items-center">
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <p className="fw-bold lead mb-0">Data Room for {getUserDetails()?.business_name}</p>
                <small style={{cursor:'pointer'}} onClick={() => window.location.href = '/data-room/upload'}>Upload all documents</small>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <Input size="large" placeholder="Search your files by category" prefix={<i className='bi bi-search'/>} suffix={<i className='bi bi-sliders'/>}
                       value={searchTerm}
                       onChange={handleSearchChange} />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <button className="btn btn-primary btn-small w-50 float-end mobile-full-width"><i className="bi bi-person-add"/> Invite</button>
            </div>
        </div>
        <div className="row" style={{paddingTop:'8%'}}>
            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <div className="row">
                    {filteredDocs.length > 0 && filteredDocs.map(item =>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4" key={item.id}>
                            <SingleFile title={item.name} subTitle='Created 1min ago' file={item.files} status={item.status}/>
                        </div>
                    )}
                    {filteredDocs.length < 1 && documents.map(item =>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4" key={item.id}>
                            <SingleFile title={item.name} subTitle='Created 1min ago' file={item.files} status={item.status}/>
                        </div>
                    )}
                </div>
            </div>
        </div>

      </div>
  )
}