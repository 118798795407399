import React from "react";
import { useSearchParams } from 'react-router-dom';
import MainNavbarForDashboard from "../components/navbarComponents/NavbarForMainDashbaord";
import PaymentProcessingLoader from "../components/PaymentProcessingLoader";

import "../css/FixedScrollingMainScreenContent.css";

const isNavbarForDashboard = true;
const PaymentProcessingPage = () => {
    const user_details = JSON.parse(sessionStorage.getItem("user_details"));
    // console.log("in main dashboard page where the logged_in_user_id = ", user_details);

    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('session_id');
    // console.log("sessionId = ", sessionId);

    const testingID = "cs_test_a10GBFOy";

    return (
        <div className="">
            <PaymentProcessingLoader user_details={user_details} sessionId={sessionId} />
        </div>
    )
};

export default PaymentProcessingPage;